

import axios from 'axios'
import publicIp from 'public-ip'
import { createConnection } from './wss-service'
import { getAppId } from '../utils/brand-silo'

const BFF_URL = 'https://zfvxemyv73.execute-api.us-east-1.amazonaws.com/dev'
// const BFF_URL = 'http://localhost:4000/dev'

const REMOTE = {
    GET_SESSION: `${BFF_URL}/session`,
    INIT_SESSION: `${BFF_URL}/init`,
    START_SESSION: `${BFF_URL}/start`,
    ACTION: `${BFF_URL}/action`,
    INVITATION:  `${BFF_URL}/invite`,
    CREATE_EVENT:  `${BFF_URL}/event`,
    GET_ALL_MESSAGES: `${BFF_URL}/messages`,
}


const getIpAddress = async () => publicIp.v4()


const getAccessToken = () => localStorage.getItem("@TESSERACT/ACCESS_TOKEN")
const getIdToken = () => localStorage.getItem("@TESSERACT/ID_TOKEN")


export const getSession = async (cognitoId, displayName = '') => {

    const appId = getAppId()
    const ipAddress = await getIpAddress()
    const userAgent = navigator.userAgent
    const accessToken = getAccessToken()
    const idToken = getIdToken()

    const request = {
        method: 'post',
        url: REMOTE.GET_SESSION,
        data: {
            appId,
            ipAddress,
            userAgent,
            accessToken,
            idToken,
            cognitoId,
            displayName
        }
    }

    
    const { data } = await axios(request)


    // console.log({ request, data })


    return data

}

export const initSession = async (displayName, status, interest, cognitoId) => {

    const appId = getAppId()
    const eventId = '420'
    const ipAddress = await getIpAddress()
    const userAgent = navigator.userAgent
    const accessToken = getAccessToken()
    const idToken = getIdToken()


    const request = {
        method: 'post',
        url: REMOTE.INIT_SESSION,
        data: {
            appId,
            eventId,
            ipAddress,
            userAgent,
            accessToken,
            idToken,
            displayName,
            status,
            interest,
            cognitoId
        }
    }


    const { data } = await axios(request)

    // console.log({ request, data })
    
    return data
}

export const startSession = async (eventId) => {

    const appId = getAppId()
    const accessToken = getAccessToken()
    const idToken = getIdToken()


    const request = {
        method: 'post',
        url: REMOTE.START_SESSION,
        data: {
            appId,
            eventId,
            accessToken,
            idToken,
        }
    }


    const { data } = await axios(request)

    // console.log({ request, data })

    return data
}

export const likeAction = async (targetId, targetType, value) => {

    if (targetId === null || targetId === undefined || targetId === '') {
        console.log('no target id !!!')
        return
    }
    const appId = getAppId()
    const accessToken = getAccessToken()
    const idToken = getIdToken()


    const request = {
        method: 'post',
        url: REMOTE.ACTION,
        data: {
            appId,
            actionDetails: {
                targetId,
                targetType,
                value,
                type: 'LIKE'
            },
            accessToken,
            idToken,
        }
    }


    const { data } = await axios(request)

    // console.log({ request, data })
    
    return data
}

export const reportAction = async (profileId, value) => {

    
    const appId = getAppId()
    const accessToken = getAccessToken()
    const idToken = getIdToken()


    const request = {
        method: 'post',
        url: REMOTE.ACTION,
        data: {
            appId,
            actionDetails: {
                type: 'REPORT',
                value,
                targetId: profileId,
            },
            accessToken,
            idToken,
        }
    }

    
    const { data } = await axios(request)

    // console.log({ request, data })

    return data
}

export const sendMessageAction = async (targetId, contextType, value) => {
    
    const appId = getAppId()
    const accessToken = getAccessToken()
    const idToken = getIdToken()


    const request = {
        method: 'post',
        url: REMOTE.ACTION,
        data: {
            appId,
            actionDetails: {
                type: 'SEND_MESSAGE',
                value,
                contextType,
                targetId,
            },
            accessToken,
            idToken,
        }
    }

    
    const { data } = await axios(request)

    // console.log({ request, data })
    
    return data

}



export const getMessagesAction = async (targetId, contextType, contextHash) => {
    
    const appId = getAppId()
    const accessToken = getAccessToken()
    const idToken = getIdToken()

    const request = {
        method: 'post',
        url: REMOTE.GET_ALL_MESSAGES,
        data: {
            appId,
            contextType,
            targetId,
            contextHash,
            accessToken,
            idToken,
        }
    }
    
    const { data } = await axios(request)

    console.log({ request, data })

    return data

}

export const getAllChatContexts = async () => {
    
    const appId = getAppId()
    const accessToken = getAccessToken()
    const idToken = getIdToken()

    const request = {
        method: 'post',
        url: REMOTE.GET_ALL_MESSAGES,
        data: {
            appId,
            accessToken,
            idToken,
        }
    }
    
    const { data } = await axios(request)

    console.log({ request, data })

    return data

}







export const createAppEvent = async (title, description, streamType, entityId) => {

    const appId = getAppId()
    const accessToken = getAccessToken()
    const idToken = getIdToken()

    const request = {
        method: 'post',
        url: REMOTE.CREATE_EVENT,
        data: {
            appId,
            accessToken,
            idToken,
            title, 
            description, 
            entityId, 
            streamType
        }
    }
    
    const { data } = await axios(request)

    // console.log({ request, data })

    return data

}

