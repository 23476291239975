



export const PAGES = {
    LANDING: 'LANDING',
    EXPLORE: 'EXPLORE',
    EVENT: 'EVENT',
    PROFILE: 'PROFILE',
    LOGIN: 'LOGIN',
    EVENT_DETAILS: 'EVENT_DETAILS',
    MESSENGER: 'MESSENGER'
}

