import React, { useEffect, useState } from 'react'

import Amplify, { Auth, Hub } from 'aws-amplify';
import { Container, Row, Col } from 'reactstrap'

import { AmplifyAuthenticator } from '@aws-amplify/ui-react';


const AuthorizeComponent = ({ cognitoUser, page, appData, render }) => {

    const pageConfig = appData.pageConfigs.find(c => c.key === page)
    const { isAuthorized } = pageConfig

    const authTheme = {
            backgroundColor: 'red',
            color: 'red'
    }


    if (isAuthorized) {

        return (
            <Row className="w-100 mx-0" >
                <Col xs={12} className="text-center px-0"  >
                    <AmplifyAuthenticator theme={authTheme} >
                        { render() }
                    </AmplifyAuthenticator>  
                </Col>
            </Row>
          )
    } else {
        return render() 
          
    }
}

export default AuthorizeComponent