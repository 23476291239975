import React, { useState, useEffect } from 'react'
import { ChatFeed, Message } from 'react-chat-ui'
import { Row, Col, Input, Button, Form, FormGroup, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { getMessagesAction } from '../../services/bff-service'



const MessengerComponent = ({ profile, setMessengerContext, messenger, sendMessage }) => {

    // console.log('MessengerComponent')
    // console.log({ profile })

    const [message, setMessage] = useState('')

    const messengerStyle = {
        zIndex: 10000,
        position: 'absolute',
        right: '20px',
        bottom: '20px',
        borderRadius: '5px',
        maxWidth: '350px'
    }

    const strongMessages = messenger.messages && messenger.messages.length 
        ? messenger.messages
            .sort( (a, b) => a.timestampUnix - b.timestampUnix )
            .map( (m, index) => {

                const { profileId } = profile

                // console.log({ myProfileId: profileId, messageProfileId: m.profileId })


                const sentByMe = profileId === m.profileId

                const id = sentByMe ? 0 : index + 1
                const newMessage = {
                    id, 
                    message: m.text, 
                    senderName: id !== 0 ? m.displayName : undefined
                }

                return new Message(newMessage)
    }) : []


    const toggle = () => setMessengerContext(null, null, false)

    const handleChange = (e) => {
        const { target } = e
        setMessage(target.value)
    }

    const triggerSendMessage = (e) => {
        e.preventDefault()
        if (message !== '' && message !== undefined) {
            const { contextId, contextType } = messenger
            sendMessage(message)
            setMessage('')
        }
    }


    return (
        <Row className="bg-secondary" style={messengerStyle}>
            <Col>
                { messenger.isOpen && 
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col className="d-flex" >
                                    <span className="my-auto" >
                                        { messenger.isLoading ? 'Loading...' : messenger.displayName }
                                    </span>
                                </Col>
                                <Col className="text-right">
                                    <Button onClick={toggle}><FontAwesomeIcon icon={faTimes} /></Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} style={{ backgroundColor: 'white' }} >
                            <ChatFeed
                                    maxHeight={500}
                                    messages={strongMessages}
                                    hasInputField={false}
                                    showSenderName
                                    bubblesCentered={false} 
                                />
                        </Col>
                        <Col xs={12} className="p-0" >
                            <Form onSubmit={triggerSendMessage} >
                                <Input value={message} onChange={handleChange} placeholder='Type a message...' style={{ height: '60px' }} />
                            </Form>
                        </Col>
                    </Row>
                }

            </Col>
        </Row>
    )
}

export default MessengerComponent