
import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faArrowRight, faCog } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Tooltip, Spinner } from 'reactstrap';
import { likeAction, reportAction } from '../../services/bff-service'
import ViewerStreamComponent from '../viewer-stream-component'



const resolveGridConfig = (num, hasHeader) => {


  if (hasHeader) {
    return {
      xs: 12,
      sm: 12,
      lg: 12,
      xl: 12
    } 
  }


  switch (num) {
    case 1:

      return {
        xs: 12,
        sm: 12,
        lg: 12,
        xl: 12
      }
    case 2:
      return {
        xs: 12,
        sm: 12,
        lg: 6,
        xl: 6
      }      
    case 3:
      return {
        xs: 12,
        sm: 6,
        lg: 6,
        xl: 6
      }    
    case 4:
      return {
        xs: 6,
        sm: 6,
        lg: 6,
        xl: 6
      }    
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      return {
        xs: 6,
        sm: 4,
        lg: 4,
        xl: 4
      }    
      
    default:
      return {
        xs: 12,
        sm: 12,
        lg: 12,
        xl: 12
      } 
  }


}



const ReportUserModal = ({ isOpen, toggle, profileId }) => {


  const [reportType, setReportType] = useState('Abues')

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'violationType') {
      setReportType(value)
    }
}


  const reportUser = () => {
    reportAction(profileId, reportType)
    toggle()
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ zIndex: 6000 }} >
      <ModalHeader toggle={toggle} style={{ zIndex: 6000 }}>Report a User</ModalHeader>
      <ModalBody style={{ zIndex: 6000 }}>
        What did the user do wrong?
        <FormGroup>
          <Label for="violationType">Violation Type</Label>
          <Input type="select" name="violationType" id="violationType" onChange={handleChange} style={{ zIndex: 6500}} >
            <option>Abuse</option>
            <option>Nudity</option>
            <option>Fake Account</option>
          </Input>
        </FormGroup>
        
      </ModalBody>
      <ModalFooter style={{ zIndex: 6000 }}  >
        <Button color="primary" block onClick={reportUser}  style={{ zIndex: 6500 }}>Submit</Button>
        <Button color="secondary" block onClick={toggle}  style={{ zIndex: 6500 }}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}



const PartyComponent = ({ profile, partyId, partyDetails, event, partyColSize }) => {  

  const { peers } = partyDetails
  const peersToShow = peers.filter( peer => peer !== null && peer.profileId !== profile.profileId)
  const config = resolveGridConfig(peersToShow.length, event.showHeader)

  const [showModal, setShowModal] = useState(false)
  const [reportPeer, setReportPeer] = useState(undefined)

  const toggle = () => setShowModal(!showModal)

  const reportUser = (profileId) => {
    setShowModal(true)
    setReportPeer(profileId)
  }

  const likeUser = (targetId, value) => {
    const targetType = 'PEER'
    likeAction(targetId, targetType, value)
  }

  const myFriends = profile.friends ? profile.friends : []

  return (
    <>

      { showModal && reportPeer && <ReportUserModal profileId={reportPeer} isOpen={!!reportPeer} toggle={toggle} />}

      { peersToShow.length > 0 && peersToShow.map((peer, i) => {

          const peerIsFriend = !!myFriends.find( f => f.profileId === peer.profileId )

          return (
            <Col key={peer.profileId} xs={partyColSize.xs} md={partyColSize.md} style={{padding: '0px' }} >
              <ViewerStreamComponent isLiked={peerIsFriend} peer={peer} index={i} reportUser={reportUser} likeUser={likeUser} hostProfileId={profile.profileId} />
            </Col>
          )
        }
      )}

      { peersToShow.length === 0 &&
        <Col xs={6} className="d-flex" >
          <span className="m-auto text-center" >
            <Spinner color="primary" />
            <br />
            Waiting for other users
          </span>
        </Col>
      }

    </>
  )
}


export default PartyComponent

































