import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Row, Col } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faLock  } from '@fortawesome/free-solid-svg-icons'




const getCognitioAttributes = (cognito) => cognito && cognito.attributes ? cognito.attributes : { sub: null }



const SsoComponent = ({ appConfig, isOpen, toggle, authUser, getAuthenticatedSession }) => {

    const [ fetchStatus, setFetchStatus ] = useState(0)
    const [ authorizationStatus, setAuthorizationStatus ] = useState(0)
    const [ syncronizationStatus, setSyncronizationStatus ] = useState(0)


    const shouldShow = appConfig.forceSso && isOpen

    const canContinue = syncronizationStatus === 2

    useEffect(() => {

        if (shouldShow) {

            const { sub: cognitoId, email } = getCognitioAttributes(authUser)
            const displayName = email.substring(0, email.indexOf('@'))

            getAuthenticatedSession({ cognitoId, displayName })


            setTimeout(() => {
                setFetchStatus(1)
            }, 2000)

            setTimeout(() => {
                setFetchStatus(2)
                setAuthorizationStatus(1)
            }, 3000)


            setTimeout(() => {
                setAuthorizationStatus(2)
                setSyncronizationStatus(1)
            }, 4000)


            setTimeout(() => {
                setSyncronizationStatus(2)
            }, 5000)


        }


    }, [ isOpen ])



    const getStatusItem = (status) => {

        switch (status) {
            case 0:
                return <></>
            case 1:
                return <Spinner />
            case 2:
                return <FontAwesomeIcon icon={faCheckCircle} size="2x"  />
            default:
                return <></>
        }
    }

    return (
        <>
            <Modal isOpen={shouldShow}  >
                <ModalHeader >Welcome to { appConfig.name }! </ModalHeader>
                <ModalBody>
                

                    <Row className="mb-5">
                        <Col xs={12} className="text-center"  >
                            You have an account with one of our app partners, please wait while we authorize your profile.
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={3} className="text-right"  >
                            { getStatusItem(fetchStatus) }
                        </Col>

                        <Col xs={9}  >
                            Fetching data
                        </Col>
                    </Row>

                    <Row className="my-2" >
                        <Col xs={3} className="text-right"  >
                            { getStatusItem(authorizationStatus) }
                        </Col>
                        <Col xs={9}  >
                            Authorizing user
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={3} className="text-right"  >
                            { getStatusItem(syncronizationStatus) }
                        </Col>
                        <Col xs={9}  >
                            Syncronizing accounts
                        </Col>
                    </Row>


                    <Row className="mt-5" >
                        <Col xs={12} text-center>
                            {/* <Button color="primary" onClick={toggle} block disabled={!canContinue}> */}
                            <Button color="primary" onClick={toggle} block disabled={!canContinue}>
                                { canContinue ? 'Continue' : 'Loading...' }
                            </Button>
                        </Col>
                    </Row>


                </ModalBody>
                <ModalFooter>
                    <Row className="w-100" >
                        <Col className="text-center" >
                            <FontAwesomeIcon icon={faLock} /> Secured by Tesseract Media
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    )

}



export default SsoComponent