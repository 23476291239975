import React, { useState, useEffect } from 'react';
import { Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavLink, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube, faCompass, faUser, faHome, faPaperPlane,  } from '@fortawesome/free-solid-svg-icons'
import { PAGES } from '../../utils/pages'


const AppLogo = ({ appId, logo }) => {

    let style = {}

    switch(appId) {
        case 'APELLA':
            style = { fontSize: '40px' }
            break
        case 'SPEAKEASY':
            style = { fontSize: '34px' }
            break
        case 'TESSERACT':
            return ( <strong> { logo } </strong> )
        default:
            break
    }

    return (
        <span style={style} >
            { logo }
        </span>
    )
}

const NavComponent = ({ cognitoUser, appConfig, currentView, goTo, profile, toggleMessenger, showMessenger }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownOpen2, setDropdownOpen2] = useState(false)

  const toggle = () => setDropdownOpen(!dropdownOpen)

  const hasProfile = !!profile

  const hasCognito = !!cognitoUser


  return (
    <Row className="my-2 w-100" >
        <Col className=""  >
            <Nav pills >

                <NavItem className="mr-auto">
                    <NavLink className="font-logo" onClick={goTo(PAGES.LANDING)} href="#" >

                        <AppLogo appId={appConfig.appId} logo={appConfig.logo} />

                    </NavLink>
                </NavItem>
                

                { appConfig.pages.includes(PAGES.LANDING) && 
                    <NavItem>
                        <NavLink active={currentView.path === PAGES.LANDING } onClick={goTo(PAGES.LANDING)}  className="" href="#">
                            <FontAwesomeIcon icon={faHome}  />
                            <span className="d-sm-inline d-none ml-2 font-weight-light" >HOME</span>
                        </NavLink>
                    </NavItem>}


                { appConfig.pages.includes(PAGES.EXPLORE) &&
                    <NavItem>
                        <NavLink active={currentView.path === PAGES.EXPLORE } onClick={goTo(PAGES.EXPLORE)}  className="" href="#">
                            <FontAwesomeIcon icon={faCompass}  />
                            <span className="d-sm-inline d-none ml-2 font-weight-light" >EXPLORE</span>
                        </NavLink>
                    </NavItem>}


                { appConfig.pages.includes(PAGES.PROFILE) 
                    ? (hasProfile || hasCognito) 
                        ?   <NavItem>
                                <NavLink active={currentView.path === PAGES.PROFILE } onClick={goTo(PAGES.PROFILE)}  className="" href="#">
                                    <FontAwesomeIcon icon={faUser}  />
                                    <span className="d-sm-inline d-none ml-2 font-weight-light" >PROFILE</span>
                                </NavLink>
                            </NavItem>
                        :   <NavItem>
                                <NavLink active={currentView.path === PAGES.PROFILE } onClick={goTo(PAGES.PROFILE)}  className="" href="#">
                                    <FontAwesomeIcon icon={faUser}  />
                                    <span className="d-sm-inline d-none ml-2 font-weight-light" >LOG IN</span>
                                </NavLink>
                            </NavItem>
                    : <></>
                }



                { appConfig.pages.includes(PAGES.MESSENGER) && (hasProfile || hasCognito) && 
                    <NavItem>
                        <NavLink active={showMessenger } onClick={toggleMessenger}  className="" href="#">
                            <FontAwesomeIcon icon={faPaperPlane}  />
                            <span className="d-sm-inline d-none ml-2 font-weight-light" >MESSENGER</span>
                        </NavLink>
                    </NavItem>}
                    



            </Nav>
        </Col>

    </Row>
  )
}

export default NavComponent
