import React, { useState } from 'react'
import Helmet from 'react-helmet'
import adapter from 'webrtc-adapter';
import AppContext from './context'
import AppDirector from './director'
import AppTheme from './theme'
import MessengerComponent from '../messenger'


const AppOrchestrator = () => {

    return (
        <AppContext render={ (appData, authUser, notification, setMessengerContext, partyDetails, addPeers, profile, messenger, sendMessage, setProfile, linkProfileRef, showSystemUserModal, toggleSystemUserModal, getAuthenticatedSession, appEvents, isSessionLoaded) => (
            <AppTheme render={() => (
                <>
                    <Helmet defaultTitle={appData.name}  />
                    <MessengerComponent messenger={messenger} setMessengerContext={setMessengerContext} sendMessage={sendMessage} profile={profile} />
                    <AppDirector 
                        appData={appData} 
                        authUser={authUser} 
                        partyDetails={partyDetails} 
                        addPeers={addPeers} 
                        profile={profile} 
                        setMessengerContext={setMessengerContext} 
                        setProfile={setProfile} 
                        linkProfileRef={linkProfileRef} 
                        notification={notification}
                        showSystemUserModal={showSystemUserModal}
                        toggleSystemUserModal={toggleSystemUserModal}
                        getAuthenticatedSession={getAuthenticatedSession}
                        appEvents={appEvents}
                        isSessionLoaded={isSessionLoaded}
                          />
                </>
            )} />
        )} />
    )
}

export default AppOrchestrator
