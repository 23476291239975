import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import { likeAction } from '../../services/bff-service'


const doesExist = str => (str !== null && str !== undefined && str !== '')



const LikeComponent = ({ isLikedInitial, targetId, targetType }) =>{

    const [isLiked, setIsLiked] = useState(isLikedInitial)

    const triggerLikeAction = (targetId, targetType) => async () => {
        const newValue = !isLiked
        setIsLiked(newValue)
        await likeAction(targetId, targetType, newValue)
    }

    const idToken = localStorage.getItem('@TESSERACT/ID_TOKEN')
    const accessToken = localStorage.getItem('@TESSERACT/ACCESS_TOKEN')


    const hasProfile = doesExist(idToken) && doesExist(accessToken)
    
    if (hasProfile) {
        return (
            <FontAwesomeIcon color='primary' className="cursor-pointer" icon={ isLiked ? faHeart : farHeart } onClick={triggerLikeAction(targetId, targetType)} size="2x" />
        )
    } else {
        return null
    }
}

export default LikeComponent