import React from 'react'
import { Row, Col } from 'reactstrap'
import ProfileCardComponent from '../../card/profile-card-component'
import SocialCardComponent from '../../card/social-card-component'

import { AmplifySignOut } from '@aws-amplify/ui-react';


const ProfileHeroComponent = ({ profile, authUser }) => {


    const theme = {
        borderRadius: '15px'
    }

    const hasSocials = profile && profile.socials
    
    return (
        <Row>
            { profile && 
                <Col xs={12} lg={hasSocials ? 6 : 12} >
                    <ProfileCardComponent profile={profile} />
                </Col>}
            
            { hasSocials && 
                <Col xs={12} lg={6}  >
                    <SocialCardComponent profile={profile.socials} />
                </Col>}

            { authUser && 
                <AmplifySignOut className="ml-2" theme={theme}  />
            }

        
        </Row>
    )
}

export default ProfileHeroComponent