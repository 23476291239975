import React from 'react'
import EventHeroComponent from '../../components/hero/event-hero-component'
import { Button, Container, Row, Col } from 'reactstrap'

const EventDetailsPage = ({ event, goToEvent }) => {

  
    const goToApp = () => {
      if (event.type === 'PROFILE_EVENT' ) {
        goToEvent()
      } else if ( event.type === 'REMOTE_URL') {
        if (window.location.href.includes('localhost')) {
          const launchAppUrl = window.location.href + event.path
          window.open(launchAppUrl, '_blank');
        } else {
          window.open(event.remoteUrl, '_blank');
        }
      }
    }

    const inDev = event.status === 'DEVELOPMENT'


    const buttonText = event.type === 'PROFILE_EVENT' 
      ? 'Join Event' 
      : inDev 
        ? 'In Development'
        : event.status === 'BETA'
          ? 'Launch Beta'
          : 'Launch App'

    return (
        <>
            <Container>
              <EventHeroComponent event={event} render={() => 
                <Button block color="secondary" className="mt-3" onClick={goToApp} disabled={inDev} >{ buttonText }</Button>
              } />
            </Container>
      </>
    )
}

export default EventDetailsPage