import React, { useState } from 'react';
import AWS from "aws-sdk";
import { SignalingClient, Role } from 'amazon-kinesis-video-streams-webrtc'
import { Row, Col, Button, Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle,  } from '@fortawesome/free-solid-svg-icons'
import LikeComponent from '../like-component'


const uid = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);


class LiveFeedView extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef()
        const viewerId = uid()
        console.log('[VIEWER '+viewerId+'] LiveFeedView:constructor');
        this.state = { viewerId, viewer: {} }
    }

    componentWillUnmount() {
        console.log('[VIEWER '+this.state.viewerId+'] Stopping viewer connection');
        if (this.state.viewer.signalingClient) {
            this.state.viewer.signalingClient.close();
            this.state.viewer.signalingClient = null;
        }
    
        if (this.state.viewer.peerConnection) {
            this.state.viewer.peerConnection.close();
            this.state.viewer.peerConnection = null;
        }
    
        if (this.state.viewer.remoteStream) {
            this.state.viewer.remoteStream.getTracks().forEach(track => track.stop());
            this.state.viewer.remoteStream = null;
        }
    
        if (this.state.viewer.peerConnectionStatsInterval) {
            clearInterval(this.state.viewer.peerConnectionStatsInterval);
            this.state.viewer.peerConnectionStatsInterval = null;
        }
    
        if (this.state.viewer.remoteView) {
            this.state.viewer.remoteView.srcObject = null;
        }
    
        if (this.state.viewer.dataChannel) {
            this.state.viewer.dataChannel = null;
        }
    }

    async componentDidMount() {
        // Create KVS client
        const kinesisVideoClient = new AWS.KinesisVideo({
            region: this.props.formValues.region,
            accessKeyId: this.props.formValues.accessKeyId,
            secretAccessKey: this.props.formValues.secretAccessKey,
            sessionToken: this.props.formValues.sessionToken,
            endpoint: this.props.formValues.endpoint,
        });

        const channelARN = this.props.peer.channelArn

        
        
        console.log('[VIEWER '+ this.state.viewerId +'] Channel ARN: ', channelARN);

        // Get signaling channel endpoints
        const getSignalingChannelEndpointResponse = await kinesisVideoClient.getSignalingChannelEndpoint({ ChannelARN: channelARN,
                SingleMasterChannelEndpointConfiguration: {
                    Protocols: ['WSS', 'HTTPS'],
                    Role: Role.VIEWER,
                },
            }).promise();

        const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
            endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
            return endpoints;
        }, {});
        console.log('[VIEWER '+ this.state.viewerId +'] Endpoints: ', endpointsByProtocol);

        const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
            region: this.props.formValues.region,
            accessKeyId: this.props.formValues.accessKeyId,
            secretAccessKey: this.props.formValues.secretAccessKey,
            sessionToken: this.props.formValues.sessionToken,
            endpoint: endpointsByProtocol.HTTPS,
        });

        // Get ICE server configuration
        const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient.getIceServerConfig({
                ChannelARN: channelARN,
            }).promise();

        const iceServers = [];
        iceServers.push({ urls: `stun:stun.kinesisvideo.${this.props.formValues.region}.amazonaws.com:443` });
        //if (!formValues.natTraversalDisabled) {
            getIceServerConfigResponse.IceServerList.forEach(iceServer =>
                iceServers.push({
                    urls: iceServer.Uris,
                    username: iceServer.Username,
                    credential: iceServer.Password,
                }),
            );
        //}
        console.log('[VIEWER '+ this.state.viewerId +'] ICE servers: ', iceServers);

        // Create Signaling Client
        this.state.viewer.signalingClient = new SignalingClient({
            channelARN,
            channelEndpoint: endpointsByProtocol.WSS,
            clientId:  this.state.viewerId ,
            role: Role.VIEWER,
            region: this.props.formValues.region,
            credentials: {
                accessKeyId: this.props.formValues.accessKeyId,
                secretAccessKey: this.props.formValues.secretAccessKey,
            },
        });

        const configuration = {
            iceServers,
            iceTransportPolicy: 'all',
        };
        this.state.viewer.peerConnection = new RTCPeerConnection(configuration);
        
        this.state.viewer.signalingClient.on('open', async () => {
            console.log('[VIEWER '+ this.state.viewerId +'] Connected to signaling service');

            console.log('[VIEWER '+ this.state.viewerId +'] signalingClient ');


            console.log({ signalingClient: this.state.viewer.signalingClient })

            // Create an SDP offer to send to the master
            console.log('[VIEWER '+ this.state.viewerId +'] Creating SDP offer');
            await this.state.viewer.peerConnection.setLocalDescription(
                await this.state.viewer.peerConnection.createOffer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true,
                }),
            );

            try {
                // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
                console.log('[VIEWER '+ this.state.viewerId +'] Sending SDP offer');
                this.state.viewer.signalingClient.sendSdpOffer(this.state.viewer.peerConnection.localDescription);
                console.log('[VIEWER '+ this.state.viewerId +'] Generating ICE candidates');
            } catch (err) {
                console.log('[VIEWER '+ this.state.viewerId +'] ERROR Viewer 1');
                console.log({ err })


                // viewer.signalingClient.close();
                // viewer.peerConnection.close();

                // this.props.retry()
            }
        });

        this.state.viewer.signalingClient.on('sdpAnswer', async answer => {
            // Add the SDP answer to the peer connection
            console.log('[VIEWER '+ this.state.viewerId +'] Received SDP answer');

            // this line called before setLocal(offer)
            console.log('[VIEWER '+ this.state.viewerId +']', { answer });
            
            try {
                await this.state.viewer.peerConnection.setRemoteDescription(answer);
            } catch (err) {
                console.log('[VIEWER '+ this.state.viewerId +'] ERROR Viewer 2');
                console.log({ err })

            }

        });

        this.state.viewer.signalingClient.on('iceCandidate', candidate => {
            // Add the ICE candidate received from the MASTER to the peer connection
            console.log('[VIEWER '+ this.state.viewerId +'] Received ICE candidate');
            this.state.viewer.peerConnection.addIceCandidate(candidate);
        });

        this.state.viewer.signalingClient.on('close', () => {
            console.log('[VIEWER '+ this.state.viewerId +'] Disconnected from signaling channel');
        });

        this.state.viewer.signalingClient.on('error', error => {
            console.error('[VIEWER '+ this.state.viewerId +'] Signaling client error: ', error);
        });

        // Send any ICE candidates to the other peer
        this.state.viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
            if (candidate) {
                console.log('[VIEWER '+ this.state.viewerId +'] Generated ICE candidate');

                // When trickle ICE is enabled, send the ICE candidates as they are generated.
                console.log('[VIEWER '+ this.state.viewerId +'] Sending ICE candidate');

                console.log({ candidate })

                try {
                    this.state.viewer.signalingClient.sendIceCandidate(candidate);
                } catch (err) {
                    console.log('[VIEWER '+ this.state.viewerId +'] ERROR Viewer 3');
                    console.log({ err })
                }

            } else {
                console.log('[VIEWER '+ this.state.viewerId +'] All ICE candidates have been generated');
            }
        });

        // As remote tracks are received, add them to the remote view
        this.state.viewer.peerConnection.addEventListener('track', async (event) => {
            console.log('[VIEWER '+ this.state.viewerId +'] Received remote track');
            // if (remoteView.srcObject) {
            //     return;
            // }

            console.log({event})
            this.state.viewer.remoteStream = event.streams[0];
            //this.setState({streamURL: event.streams[0]}); 
            this.videoRef.current.srcObject = event.streams[0];
        });

        console.log('[VIEWER '+ this.state.viewerId +'] Starting viewer connection');
        this.state.viewer.signalingClient.open();
    }




    triggerReportAction = () => {
        console.log('reporting user')
        const { peer } = this.props
        const { profileId } = peer
        this.props.reportUser(profileId)
    }

    peerControls = () => {

        const { peer, hostProfileId } = this.props
        const { displayName, profileId } = peer

        // console.log({ displayName, profileId })

        const formattedDisplayName = displayName.length > 10 ? `${displayName.substring(0, 9)}...` : displayName

        // console.log({ isLiked: this.state.isLiked })

        return (
            <Row style={{ position: 'relative', top: '40px', margin: 0, zIndex: 1000 }}>
                <Col >
                    { displayName && <Badge color="dark" className="mb-2" >{ formattedDisplayName }</Badge>}

                </Col>
                <Col className="text-right" >
                    { profileId && <LikeComponent targetId={profileId} targetType={'PEER'} isLikedInitial={this.props.isLiked} /> }
                    { profileId &&  <FontAwesomeIcon icon={faExclamationTriangle} onClick={this.triggerReportAction} size="2x" /> }
                </Col>
            </Row>
        )
    }


    render() {

        const style = {
            width: `100%`,
            borderRadius: '10px',
            height: '30vh',
            objectFit: 'cover',
        }

        return (
            <>
                { this.peerControls() }
                <video ref={this.videoRef} width="100%" autoPlay playsInline  style={style} />
            </>
        )
    }
}

function ViewerStreamComponent({ index, peer, reportUser, likeUser, hostProfileId, isLiked }) {

    const [showFeed, setShowFeed] = useState(true)

  const opts = {
        accessKeyId: 'AKIASFRFNP3SKHZDIXMI',
        secretAccessKey: '02Go+aaGGSnC4niVJCaKza+4uR/mpPbwCv9HutKC',
        region: 'us-east-1',
        channelName: '<your channel>'
    }

    const retry = () => {
        setShowFeed(false)
        setTimeout(() => setShowFeed(false), 100)
    }

    if (!showFeed) {
        return <></>
    }

  return (
      <LiveFeedView retry={retry} formValues={opts} index={index} peer={peer} reportUser={reportUser} likeUser={likeUser} hostProfileId={hostProfileId} isLiked={isLiked} ></LiveFeedView>
  )
}

export default ViewerStreamComponent