import React from 'react'



const ProfileCardComponent = ({ profile }) => {


    return (
        <center className="pb-3" >
            <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" 
                name="aboutme" width="140" height="140" className="rounded-circle" />
            <h3>{profile.displayName} <span style={{ color: 'gray' }}>USA</span></h3>
            <em>{ profile.interest }</em>
        </center>
    )
}

export default ProfileCardComponent