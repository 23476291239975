import React, { useEffect, useState, createRef } from 'react'
import { Row, Col, Button } from 'reactstrap'
import AWS from 'aws-sdk'
import { SignalingClient}  from 'amazon-kinesis-video-streams-webrtc'


const formValues = {
  region: 'us-east-1',
  channelName: 'tes-channel',
  clientId: 'boss-nas',
  sendVideo: true,
  sendAudio: true,
  openDataChannel: true,
  widescreen: false,
  fullscreen: true,
  useTrickleICE: true,
  natTraversalDisabled: false,
  forceTURN: false,
  accessKeyId: 'AKIASFRFNP3SKHZDIXMI', 
  endpoint: null,
  secretAccessKey: '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', 
  sessionToken: null,
  role: 'MASTER'
}


const initMaster = {
  signalingClient: null,
  peerConnectionByClientId: {},
  dataChannelByClientId: {},
  localStream: null,
  remoteStreams: [],
  peerConnectionStatsInterval: null,
}


const MasterStreamComponent = ({ getPartyDetails, channelArn }) => {

    const innerWidth = window.innerWidth

    const [master, setMaster] = useState(initMaster)

    const config = {
        width: `100%`,
        // width: `${innerWidth > 768 ? innerWidth * .1 :innerWidth * .4 }px`,
        // position: 'fixed',
        // left: '20px',
        // bottom: '20px',
        borderRadius: '10px',
        height: '30vh',
        objectFit: 'cover',
        // zIndex: 9999
    }


    useEffect(() => {
        const doAsync = async () => {  

            const kinesisVideoClient = new AWS.KinesisVideo({
                region: formValues.region,
                accessKeyId: formValues.accessKeyId,
                secretAccessKey: formValues.secretAccessKey,
                sessionToken: formValues.sessionToken,
                endpoint: formValues.endpoint,
            })
            
            const getSignalingChannelEndpointResponse = await kinesisVideoClient
                .getSignalingChannelEndpoint({
                    ChannelARN: channelArn,
                    SingleMasterChannelEndpointConfiguration: {
                        Protocols: ['WSS', 'HTTPS'],
                        Role: formValues.role,
                    },
                })
                .promise()


            const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
                endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint
                return endpoints
            }, {})

            
        const SCConfig = {
            channelARN: channelArn,
            channelEndpoint: endpointsByProtocol.WSS,
            role: formValues.role,
            region: formValues.region,
            credentials: {
                accessKeyId: formValues.accessKeyId,
                secretAccessKey: formValues.secretAccessKey,
                sessionToken: formValues.sessionToken,
            },

        }


        // Create Signaling Client
        master.signalingClient = new SignalingClient(SCConfig)

        // Get ICE server configuration
        const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
            region: formValues.region,
            accessKeyId: formValues.accessKeyId,
            secretAccessKey: formValues.secretAccessKey,
            sessionToken: formValues.sessionToken,
            endpoint: endpointsByProtocol.HTTPS,
        })

        const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
            .getIceServerConfig({
                ChannelARN: channelArn,
            })
            .promise()

        const iceServers = []
        if (!formValues.natTraversalDisabled && !formValues.forceTURN) {
            iceServers.push({ urls: `stun:stun.kinesisvideo.${formValues.region}.amazonaws.com:443` })
        }
        if (!formValues.natTraversalDisabled) {
            getIceServerConfigResponse.IceServerList.forEach(iceServer =>
                iceServers.push({
                    urls: iceServer.Uris,
                    username: iceServer.Username,
                    credential: iceServer.Password,
                }),
            )
        }

        const configuration = {
            iceServers,
            iceTransportPolicy: formValues.forceTURN ? 'relay' : 'all',
        }

        const resolution = formValues.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } }
        const constraints = {
            video: resolution,
            audio: true,
        }


        // Get a stream from the webcam and display it in the local view
        try {
            // master.localStream = await new MediaStream()
            master.localStream = await navigator.mediaDevices.getUserMedia(constraints)
            
            master.localView = master.localStream

            var video = document.getElementById('local-view')

            video.srcObject = master.localStream
            
            video.onloadedmetadata = function(e) {
                video.play()
            }
            
        } catch (e) {
            console.log({ e })
            console.error('[MASTER] Could not find webcam')
        }

        master.signalingClient.on('open', async () => {
            console.log('[MASTER] Connected to signaling service')
            getPartyDetails()
        })

        master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
            console.log('[MASTER] Received SDP offer from client: ' + remoteClientId)

            // Create a new peer connection using the offer from the given client
            const peerConnection = new RTCPeerConnection(configuration)
            master.peerConnectionByClientId[remoteClientId] = peerConnection


            // Send any ICE candidates to the other peer
            peerConnection.addEventListener('icecandidate', ({ candidate }) => {
                if (candidate) {
                    console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId)

                    // When trickle ICE is enabled, send the ICE candidates as they are generated.
                    if (formValues.useTrickleICE) {
                        console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId)
                        master.signalingClient.sendIceCandidate(candidate, remoteClientId)
                    }
                } else {
                    console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId)

                    // When trickle ICE is disabled, send the answer now that all the ICE candidates have ben generated.
                    if (!formValues.useTrickleICE) {
                        console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId)
                        master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId)
                    }
                }
            })

            // // As remote tracks are received, add them to the remote view
            // peerConnection.addEventListener('track', event => {
            //     console.log('[MASTER] Received remote track from client: ' + remoteClientId)
                
            //     console.log({ remoteView: master.remoteView })
                
            //     if (master.remoteView) {
            //         return
            //     }

            //     master.remoteView = event.streams[0]

            //     var video = document.getElementById('local-view')

            //     video.srcObject = event.streams[0]
                
            //     video.onloadedmetadata = function(e) {
            //         video.play()
            //     }

            // })

            master.localStream.getTracks().forEach(track => peerConnection.addTrack(track, master.localStream))
            await peerConnection.setRemoteDescription(offer)

            // Create an SDP answer to send back to the client
            console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId)
            await peerConnection.setLocalDescription(
                await peerConnection.createAnswer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true,
                }),
            )

            // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
            if (formValues.useTrickleICE) {
                console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId)
                master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId)
            }
            console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId)
        })

        master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
            console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId)

            // Add the ICE candidate received from the client to the peer connection
            const peerConnection = master.peerConnectionByClientId[remoteClientId]
            peerConnection.addIceCandidate(candidate)
        })

        master.signalingClient.on('close', () => {
            console.log('[MASTER] Disconnected from signaling channel')
        })

        master.signalingClient.on('error', () => {
            console.error('[MASTER] Signaling client error')
        })

        console.log('[MASTER] Starting master connection')

        master.signalingClient.open()
        // console.log({ master })

        }

        doAsync()


    }, [])


    useEffect(() => {

        return () => {

            console.log('[MASTER] Stopping MASTER connection');
            if (master.signalingClient) {
                master.signalingClient.close();
                master.signalingClient = null;
            }
        
            if (master.peerConnection) {
                master.peerConnection.close();
                master.peerConnection = null;
            }
        
            if (master.localStream) {
                master.localStream.getTracks().forEach(track => track.stop())
                master.localStream = null;
            }
        
            // if (master.peerConnectionStatsInterval) {
            //     console.log('master.localStream close')

            //     // clearInterval(master.peerConnectionStatsInterval);
            //     master.peerConnectionStatsInterval = null;
            // }
        
            if (master.remoteView) {
                master.remoteView.srcObject = null;
            }
        
            if (master.dataChannel) {
                master.dataChannel = null;
            }
              
        }

    }, [])

    

  return (
        <video id="local-view" style={config}  autoPlay playsInline controls muted />
    )
}




export default MasterStreamComponent

