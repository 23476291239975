import React, { useState } from 'react'
import { Row, Col, Jumbotron, Container } from 'reactstrap'
import YouTube from 'react-youtube';
import LikeComponent from '../../like-component'


const EventHeroComponent = ({ isActive = false, event, render }) => {

    console.log({ event })

    const currentHost = event.type === 'PROFILE_EVENT' ? event.hosts.find(h => h.isPrimary) : {}

    const isMobile = window.innerWidth < 768

    const mobileWidth = `${window.innerWidth * .9}`


    // https://developers.google.com/youtube/player_parameters

    const opts = {
        // width: isMobile ? mobileWidth : '640',
        width: '100%',
        // height: 'auto',
        playerVars: {
          autoplay: 1,
          playsinline: 1
        },
    }

    if (isActive) {
     
        return (
            <>
        
                <Container>
                    <Row className="w-100 mb-2">
                        <Col xs={10} className="text-left" >
                            <strong> { event.title } </strong> <br />
                            { event.description }
                        </Col>
                        <Col xs={2} className="text-right mt-auto" >
                            <LikeComponent targetId={event.Id} targetType={'EVENT'} isLikedInitial={event.isLiked} />
                        </Col>
                    </Row>
                </Container>

                <YouTube videoId={currentHost.entityId} opts={opts} />
        </>)
    }

    return (
        <Jumbotron className="text-center">
            <Row>
                <Col xs={12} className="text-left" >
                    <Row>
                        <Col xs={10}>
                            <h3>{ event.title } </h3>
                        </Col>
                        <Col xs={2} className="text-right" >
                            <LikeComponent targetId={event.eventId} targetType={'EVENT'} isLikedInitial={event.isLiked} />
                        </Col>
                    </Row>

                    <p>{ event.description }</p>
                </Col>

                <Col xs={12} md={6} >
                    { render() }
                </Col>
            </Row>
        </Jumbotron>
    )
}

export default EventHeroComponent