import React, { useState } from 'react'
import { Toast, ToastBody, ToastHeader, Spinner } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends, faEnvelope, faCube } from '@fortawesome/free-solid-svg-icons'


const resolveIcon = (type) => {
    switch (type) {
        case 'NEW_MESSAGE':
            return <FontAwesomeIcon icon={faEnvelope}  />
        default:
            return <FontAwesomeIcon icon={faEnvelope}  />
    }
}



const resolveHeaderText = (type) => {
    switch (type) {
        case 'NEW_MESSAGE':
            return 'New Message'
        default:
            return 'Notification'
    }
}


const resolveBodyText = (notification) => {
    switch (notification.type) {
        case 'NEW_MESSAGE':
            switch (notification.context) {
                case 'PEER':
                    return `${notification.senderName}: ${notification.text}`
                case 'PARTY':
                    return 'Party Chat'
                default:
                    return notification.text
            }
        default:
            return 'Notification'
    }
}


const NotificationComponent = ({ notification }) => {
    

    if (!notification) {
        return <></>
    }

    const style = {
        position: 'fixed',
        zIndex: 2000,
        top: '20px',
        width: '100%'
    }

    if (window.innerWidth > 576) {
        style.left = '45%'
    }


    const icon = resolveIcon(notification.type)


    return (
        <Toast style={style} onClick={notification.action} >
            <ToastHeader icon={icon} >
                { resolveHeaderText(notification.type) }
            </ToastHeader>
            <ToastBody>
            { resolveBodyText(notification) }
            </ToastBody>
        </Toast>
    )
}

export default NotificationComponent
