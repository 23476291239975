import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, Row, Col, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faVideo, faUser, faUserFriends, faChevronRight  } from '@fortawesome/free-solid-svg-icons'
import { getMessagesAction } from '../../services/bff-service'



const MessengerContextIcon = ({ type, className }) => {

    switch (type) {
        case 'PEER':
            return <FontAwesomeIcon className={className} icon={faUser}  />
        case 'GROUP':
            return <FontAwesomeIcon className={className} icon={faUserFriends}  />
        default:
            return <FontAwesomeIcon className={className} icon={faUserFriends} />
    }
}



const mapMessageContexts = (messenger) => {

    const contexts = Object.keys(messenger)

    if (contexts.length > 0) {
        const mapped = []
        contexts.map( key => {
            const firstMessage = messenger[key][0]
            const { displayName, targetId, contextType, contextHash, text } = firstMessage
            mapped.push({
                displayName, 
                targetId, 
                contextType, 
                contextHash, 
                preview: text,
                members: [ displayName ],    
                messages: messenger[key]
            })
        })
        console.log({ mapped })
        return mapped
    } else {
        return []
    }
}


const MessengerListModal = ({ isOpen, toggle, profile, setMessengerContext }) => {

    const [isLoading, setLoading] = useState(false)

    const [messageContexts, setContexts] = useState([])

    useEffect(() => {

        if (isOpen) {
            console.log('trying to get messages')
            setLoading(true)

            getMessagesAction()
                .then((data) => {

                    const mapped = mapMessageContexts(data)
                    setContexts(mapped)

                    console.log('i got the messages')
                    setLoading(false)
                })
        }


    }, [ isOpen ])


    const getFriendMessengerContext = ({ targetId, displayName, contextType, contextHash }) => () => {
        const isOpen = true
        setMessengerContext(contextType, targetId, isOpen, displayName, contextHash)
        toggle()
    }

    const LoadingInline = () => {
        return (
            <Row>
                <Col xs={12} className="text-center" >
                    <Spinner color="primary" />
                </Col>
            </Row>
        )
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} >
            <ModalHeader toggle={toggle}>Messenger</ModalHeader>
            <ModalBody>
                { isLoading
                    ? <LoadingInline />
                    : <ListGroup>
                        { messageContexts.length && messageContexts.map( mc =>  {
                            return(
                                <ListGroupItem onClick={getFriendMessengerContext(mc)}>

                                    <Row>
                                        <Col xs={11}>
                                            <Row>
                                                <Col xs={12} >
                                                    <MessengerContextIcon className="mr-2" type={mc.contextType} />
                                                    <b>{ mc.displayName }</b>
                                                </Col>
                                                <Col xs={12} >
                                                    {/* { mc.members.length && mc.members.map( (mem, i) => {
                                                        if (i !== 0) {
                                                            return `, ${mem}`
                                                        } else {
                                                            return mem
                                                        }
                                                    }) } */}
                                                    { mc.preview }
                                                </Col>
                                            </Row>
                                            
                                        </Col>
                                        <Col className="d-flex"  xs={1}>
                                            <FontAwesomeIcon className="my-auto" icon={faChevronRight}  />

                                        </Col>
                                    </Row>




                                </ListGroupItem>
                            )
                        })}
                    </ListGroup>
                }
            </ModalBody>

        </Modal>
    )
}


export default MessengerListModal