


const doesExist = str => {
    return (str !== null && str !== undefined && str !== '')
}

export const mapAppEvents = (events) => {
    
    
    const mapped = events.map( (event, i) => {
    
        const { eventId, title, description, streamType, entityId } = event

        const showHeader = doesExist(streamType) && doesExist(entityId)


        return {
            key: i,
            eventId,
            title,
            description,
            type: 'PROFILE_EVENT',
            showHeader,
            maxPeers: 1000000,
            maxPartySize: 5,
            hosts: [
                {
                    isPrimary: true,
                    streamType,
                    entityId,
                    name: 'Youtube Stream'
                }
            ]
        }
    } )

    return mapped
}
