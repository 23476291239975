import React from 'react'
import { Col, Row } from 'reactstrap'


const FooterComponent = ({ appConfig, page }) => {

    if (page === 'EVENT') {
        return <></>
    }

    const year = new Date().getFullYear()
    const footerCopytright = `© ${ year } ${ appConfig.name }`
    const poweredBy = <>, Powered by <a href="http://tesseract.media" target="_blank" >Tesseract Media</a></>
    const showPoweredBy = appConfig.appId !== 'TESSERACT'


    const fixedStyle = {
        position: 'fixed',
        bottom: '0px',
        width: '100%'
    }

    const stackedStyle = {
        width: '100%',
        marginTop: '50px'
    }

    const showFixed = window.innerHeight > 650

    const style = showFixed ? fixedStyle : stackedStyle

    return (
        <Row style={style} >
            <Col xs={12} className="text-center my-3" >
                
                { footerCopytright } { showPoweredBy && poweredBy }
                
            </Col>
        </Row>
    )
}

export default FooterComponent