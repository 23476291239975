import React, { useEffect, useState, useRef } from 'react'
import Amplify, { Auth, Hub } from 'aws-amplify';
import { HotboxAppConfig } from '../../../config/hotbox'
import { TesseractAppConfig } from '../../../config/tesseract'
import { ApellaAppConfig } from '../../../config/apella'
import { SpeakeasyAppConfig } from '../../../config/speakeasy'
import { getSession, sendMessageAction, getMessagesAction } from '../../../services/bff-service'
import { getAppId, getCurrentBrandConfig } from '../../../utils/brand-silo'


const notificationContexts = {
    PEER: 'PEER',
    GROUP: 'GROUP',
    PARTY: 'PARTY'
}

const notificationTypes = {
    NEW_MESSAGE: 'NEW_MESSAGE',
    
}



const AppContext = ({ render }) => {

    const initAppData = getCurrentBrandConfig()

    const EVENT_TYPES = {
        ADD_PEER: 'ADD_PEER',
        REMOVE_PEER: 'REMOVE_PEER',
        NEW_MESSAGE: 'NEW_MESSAGE',
        PEER: 'PEER'
    }

    const [ appData, setAppData ] = useState(initAppData)
    const [ profile, setProfile ] = useState(undefined)
    const [ authUser, setAuthUser ] = useState(null)
    const [ notification, setNotification ] = useState()
    const [ messenger, setMessenger ] = useState({ messages: [] })
    const [ partyDetails, setPartyDetails ] = useState({ peers: [] })
    const [ appEvents, setAppEvents ] = useState([])
    const [ showSystemUserModal, setShowSystemUserModal ] = useState(false)
    const [isSessionLoaded, setSessionLoaded] = useState(false)

    const toggleSystemUserModal = () => setShowSystemUserModal(!showSystemUserModal)

    const partyDetailsRef = useRef(partyDetails)
    partyDetailsRef.current = partyDetails

    const messengerRef = useRef(messenger)
    messengerRef.current = messenger 

    const profileRef = useRef(profile)
    profileRef.current = profile

    const linkProfileRef = useRef(() => {})



    const setMessengerContext = async (contextType = '', targetId = '', isOpen = true, displayName, contextHash = '') => {
        let messages = []
        
        if (isOpen && !messengerRef.current.isOpen) {
            console.log({ targetId, contextType, contextHash })

            messages = await getMessagesAction(targetId, contextType, contextHash)
        }
        setMessenger({ isOpen, isLoading: false, contextType, targetId, messages, displayName, contextHash })
    }



    const sendMessage = (message) => {
        const { targetId, contextType } = messengerRef.current
        sendMessageAction(targetId, contextType, message)
        const { profileId } = profile

        console.log(messengerRef.current)
        setMessenger({ 
            ...messengerRef.current, 
            messages: [
                ...messengerRef.current.messages,
                { text: message, profileId }
            ]
        })
    }



    const addPeers = (peers) => {
        const currentPartyDetails = partyDetailsRef.current
        const oldSet = currentPartyDetails.peers
        const newSet = [...oldSet, ...peers ]
        setPartyDetails({ peers: newSet })
        console.log('addPeers', { oldSet, newSet })
    }

    const newPeerHandler = (msg) => {
        console.log('newPeerHandler', { msg })
        const { channelArn, profileId, displayName, interest } = msg.profile
        const mapped = {
            channelArn,
            profileId, 
            displayName, 
            interest
        }
        addPeers([ mapped ])
    }

    const removePeerHandler = (msg) => {
        const currentPartyDetails = partyDetailsRef.current
        const { profileId } = msg.profile
        const oldSet = currentPartyDetails.peers
        const newSet = oldSet.filter(p => p.profileId !== profileId)
        setPartyDetails({ peers: newSet })
        console.log('removePeer', { oldSet, newSet })
    }



    const newMessageHandler = async (msg) => {
        console.log('newMessageHAndler', { msg })

        const { text, profileId, displayName, targetId, contextType, messageId, contextHash } = msg.message

        // dont show notifications for messages you sent
        if (profileId === profileRef.current.profileId) {
            return
        }

        let target = ''
        let contextName = ''

        switch (contextType) {
            case 'PEER':
                target = profileId
                contextName = displayName
                break
            case 'PARTY':
                target = targetId
                contextName = 'Party Chat'
                break
            default:
                target = 'none'
                break
        }


        let { messages } = messengerRef.current

        if (messages.contextHash === contextHash) {
            console.log('here')
            messages = [ ...messengerRef.current.messages, msg.message]
        } else {
            console.log('there', { targetId, contextType, contextHash })
            messages = await getMessagesAction(targetId, contextType, contextHash)
        }
    
        console.log({ messages })

        if (messengerRef.current.contextHash !== contextHash) {

            const action = async () => {
                console.log('action fired')
                console.log('getting messages')
                console.log({ current: messengerRef.current })
                console.log({ messages })

                setMessenger({
                    ...messengerRef.current,
                    isOpen: true,
                    messages
                })
                setNotification(null)
            }

            const notification = {
                context: notificationContexts.PEER,
                type: notificationTypes.NEW_MESSAGE,
                text: text,
                senderName: displayName,
                action
            }

            setNotification(notification)
        }

        setMessenger({ 
            ...messengerRef.current, 
            messages,
            contextType,
            displayName: contextName,
            targetId: target,
            isLoading: false
        })
    }

    const handleWsMessage = (event) => {
        const { data } = event
        const payload = JSON.parse(data)

        switch (payload.type) {
            case EVENT_TYPES.ADD_PEER:
                newPeerHandler(payload)
                break
            case EVENT_TYPES.REMOVE_PEER:
                removePeerHandler(payload)
                break
            case EVENT_TYPES.NEW_MESSAGE:
                newMessageHandler(payload)
                break
            default:
                break
        }
    }




    const getCognitioAttributes = (cognito) => cognito && cognito.attributes ? cognito.attributes : { sub: null }
    

    const getAuthenticatedSession = ({cognitoId, createWssConnection = false, wssConnect, displayName = '' }) => {

        getSession(cognitoId, displayName).then( data => {
            console.log({ getSessionData: data })

            if (data.appConfig) {
                setAppData({ ...initAppData, ...data.appConfig })
            }

            setSessionLoaded(true)

            if (data.tokens) {
                const { tokens } = data
                const { accessToken, idToken } = tokens
                localStorage.setItem("@TESSERACT/ACCESS_TOKEN", accessToken)
                localStorage.setItem("@TESSERACT/ID_TOKEN", idToken)
            }

            if (data.events) {
                setAppEvents(data.events)
            }

            if (data.profile) {
                if (createWssConnection) {
                    wssConnect({ profileId: data.profile.profileId })
                    // createConnection({ profileId: data.profile.profileId })
                }

                setProfile(data.profile)
                const profileString = JSON.stringify(data.profile)
                localStorage.setItem("@TESSERACT/PROFILE", profileString)
            } else if (cognitoId !== null &&cognitoId !== undefined ) {
                toggleSystemUserModal()
            } 

        })
    }



    useEffect(() => {

        const wssUrl = 'wss://x9tshwmquh.execute-api.us-east-1.amazonaws.com/dev'
        const tesseractSocket = new WebSocket(wssUrl)

    
        tesseractSocket.onopen = () => {
            // console.log('ws open')
        }

        tesseractSocket.onmessage = (event) => {
            // console.log('ws message')
            handleWsMessage(event)
        }


        const createConnection = async ({ profileId, channelArn }) => {
            // console.log('linking profile: ' + profileId)
            const type = 'LINK_PROFILE'
            const appId = getAppId()

            const createConnectionMessage = { type, profileId, appId, channelArn }
            const msg = `{ "action": "sendMessage", "data": ${ JSON.stringify(createConnectionMessage) } }`
            await tesseractSocket.send(msg)

        }

        linkProfileRef.current = createConnection



        const { appId: currentProfileAppId } = profileRef.current
            ? profileRef.current
            : { appId: '' }

        // if (currentProfileAppId !== localStorage.getItem("@TESSERACT/APP_ID")) {
        //     localStorage.removeItem('@TESSERACT/PROFILE')
        // }









        Hub.listen('auth',  e => {

            const { event: eventType } = e.payload

            switch (eventType) {
                case 'signOut':
                    localStorage.removeItem('@TESSERACT/ID_TOKEN')
                    localStorage.removeItem('@TESSERACT/ACCESS_TOKEN')
                    localStorage.removeItem('@TESSERACT/PROFILE')
                    window.location.reload()

                    break
                case 'signIn':
                    const { data: cognitoUser } = e.payload
                    if (cognitoUser && cognitoUser.attributes) {
                        const { sub: cognitoId, email } = getCognitioAttributes(cognitoUser)
                        const displayName = email.substring(0, email.indexOf('@'))
                        setAuthUser(cognitoUser)
                        !profile && getAuthenticatedSession({cognitoId, wssConnect: createConnection, displayName})
                    }
                    break
                default:
                    break
            }

        })




        Auth.currentAuthenticatedUser()
            .then( cognito => {
                const { sub: cognitoId } = getCognitioAttributes(cognito)
                
                if (cognito) {
                    setAuthUser(cognito)
                }
                const createWssConnection = true
                getAuthenticatedSession({cognitoId, wssConnect: createConnection, createWssConnection})

            })
            .catch(err => {
                const cognitoId = null
                const createWssConnection = true
                getAuthenticatedSession({cognitoId, wssConnect: createConnection, createWssConnection})

            })

    }, [])


    return render(appData, authUser, notification, setMessengerContext, partyDetails, addPeers, profileRef.current, messengerRef.current, sendMessage, setProfile, linkProfileRef, showSystemUserModal, toggleSystemUserModal, getAuthenticatedSession, appEvents, isSessionLoaded)
}

export default AppContext
