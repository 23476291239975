

export const HotboxAppConfig = {
  appId: 'HOTBOX',
  name: 'Hotbox',
  logo: 'HOTBOX',
  pages: [
    'LANDING',
    // 'EXPLORE',
    'PROFILE',
    'MESSENGER'
  ],
  type: 'SINGLE',
  forceSso: true,
  landing: {
    page: 'LANDING',
    header: `IT'S 4:20 SOMEWHERE`,
    subHeader: 'BRINGING THE WORLD TOGETHER ONE TOKE AT A TIME',
    cta: {
      primary: {
        text: 'FIND A HOTBOX',
        target: 'EVENT'

      },
      secondary: {
        text: 'LEARN MORE',
        target: 'EVENT'
      }
    }
  },
  pageConfigs: [
    {
      key: 'PROFILE',
      isAuthorized: true,
      tabs: [
        // 'EVENTS'
        'FRIENDS',
        // 'GROUPS'
      ]
    },
    {
      key: 'EXPLORE',
      isAuthorized: false,
      tabs: [ ]
    },
    {
      key: 'EVENT',
      isAuthorized: false,
      tabs: [ ]
    }
  ],
  event: {
    eventId: '420',
    name: 'Hotbox Social',
    description: `It's 4:20 somewhere`,
    showHeader: false,
    maxPeers: 1000000,
    maxPartySize: 9
  },
  theme: {
    color: {
      primary: '#7fcd91',   // green
      secondary: '#4d4646', // dark gray
      tertiary: '#5b5656',  // gray
      quaternary: '#f5eaea' // light pink
    },
    type: {
      primary: 'Oswald',
      secondary: 'Oswald'
    }
  }
}

