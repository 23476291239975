import React, { useEffect, useState } from 'react'
import {Launcher} from 'react-chat-window'
import { Row, Col, Form, FormGroup, Label, Input, Button, Spinner, Container, Tooltip } from 'reactstrap'
import MasterStreamComponent from '../../../master-stream-component'
import PartyComponent from '../../../party-component'
import EventHeroComponent from '../../../hero/event-hero-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faArrowRight, faCog } from '@fortawesome/free-solid-svg-icons'

import { initSession, startSession } from '../../../../services/bff-service'

const isRealString = str => ( str !== null && str !== undefined && str !== '' )

const PrePartyFormView = ({ profile, formDetails, submitPreParty, isSessionLoaded }) => {
    

    const [displayName, setDisplayName] = useState(profile ? profile.displayName : '')
    const [interest, setInterest] = useState(profile ? profile.interest : '')
    const [status, setStatus] = useState(profile ? profile.status : '')

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (isRealString(displayName) && isRealString(status)) {
            submitPreParty(displayName, status, interest)
            setIsLoading(true)
        }
    
    }, [])


    const submitForm = (e) => {
        e.preventDefault()

        submitPreParty(displayName, status, interest)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'displayName') {
            setDisplayName(value)
        }
        if (name === 'interest' ) {
            setInterest(value)
        }
    }

    if (isLoading || !isSessionLoaded) {
        return (
            <Container>
                <Row>
                    <Col xs={12} className="text-center mt-5">
                        <Spinner color="primary" />
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <Container>
            <Row>
                <Col className="text-left" xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }} >
                    <Form onSubmit={submitForm} >
                        <FormGroup>
                            <Label for="displayName">Display Name</Label>
                            <Input type="text" name="displayName" id="displayName" value={displayName} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="interest">Interest</Label>
                            <Input type="text" name="interest" id="interest" value={interest}  onChange={handleChange} />
                        </FormGroup>
                        <Button block color="primary" type="submit"  >Lets Go</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

const IntermissionView = ({ goToParty, partyColSize }) => {

    useEffect(() => {
        setTimeout(goToParty, 1000)
    }, [])

    return (
        <Col key={'INTERMISSION'} xs={partyColSize.xs} md={partyColSize.md} className="d-flex" >
            <span className="m-auto text-center" >
            <Spinner color="primary" />
            <br />
            Looking for next party
            </span>
        </Col>
    )
}

const EventDirector = ({ appConfig, authUser, profile, event, partyDetails, addPeers, setMessengerContext, setProfile, linkProfileRef, isSessionLoaded }) => {

    console.log({ event })

    const initPartyDetails = {
        partyId: 'asdfasdf',
        peers: []
    }


    const PAGES = {
        PRE_EVENT_FORM: 'PRE_EVENT_FORM',
        IN_PARTY: 'IN_PARTY',
        INTERMISSION: 'INTERMISSION'    
    }

    const initial = {
        page: PAGES.PRE_EVENT_FORM
    }

    const [ current, setCurrent ] = useState(initial)

    const [isShowingMaster, setShowingMaster] = useState(false)
    const [hostDetails, setHostDetails] = useState(null)
    const [currentPartyId, setPartyId] = useState('none')

    
    const submitPreParty = (displayName, status, interest) => {

        const { sub: cognitoId } = authUser && authUser.attributes ? authUser.attributes : { sub: null }
        
        initSession(displayName, status, interest, cognitoId).then( (response) => {

            const { channel, tokens, profile: respProfile } = response
            const { idToken, accessToken } = tokens
            const { channelArn, channelName} = channel

            linkProfileRef.current({ profileId: respProfile.profileId, channelArn })

            localStorage.setItem("@TESSERACT/ACCESS_TOKEN", accessToken)
            localStorage.setItem("@TESSERACT/ID_TOKEN", idToken)
            // localStorage.setItem("@TESSERACT/PROFILE", JSON.stringify(respProfile))

            setProfile(respProfile)
            setHostDetails({ displayName, status, interest, channelArn, channelName })
            setShowingMaster(true)    
        })
    }

    const goToIntermission = () => {
        setCurrent({ page: PAGES.INTERMISSION })
    }

    const goToParty = () => {
        startSession(event.eventId).then( (startSessionResponse) => {
            const { peers, partyId } = startSessionResponse
            addPeers(peers)
            setPartyId(partyId)
            setCurrent({ page: PAGES.IN_PARTY })    
        }) 

    }


    const resolveView = () => {
        

        switch(current.page) {
            case PAGES.PRE_EVENT_FORM:
                return <PrePartyFormView profile={profile} formDetails={{}} submitPreParty={submitPreParty} isSessionLoaded={isSessionLoaded} />
            case PAGES.INTERMISSION:
                return <></>
                return <IntermissionView goToParty={goToParty} />
            case PAGES.IN_PARTY:
                return <></>
                return (
                    <PartyComponent 
                        hasHeader={event.showHeader}
                        profile={profile}
                        partyId={currentPartyId}
                        partyDetails={partyDetails} 
                        profileId={hostDetails.channelName} 
                        event={event} 
                        nextInteraction={goToIntermission}
                        setMessengerContext={setMessengerContext}
                    /> 
                )
            default:
                return <> default </ >
        }
    } 


    const fabStyle = 'rounded-circle btn-lg'


    const [nextTooltipOpen, setNextTooltipOpen] = useState(false)
    const [messengerTooltipOpen, setMessengerTooltipOpen] = useState(false)
    const [settingsTooltipOpen, setSettingsTooltipOpen] = useState(false)
  
    const toggleNextTt = () => setNextTooltipOpen(!nextTooltipOpen)
    const toggleMessengerTt = () => setMessengerTooltipOpen(!messengerTooltipOpen)
    const toggleSettingsTt = () => setSettingsTooltipOpen(!settingsTooltipOpen)
  

    // console.log({ page: current.page })

    const openMessengerContext = () => {
        const contextType = 'PARTY'
        const displayName = 'Party Chat'
        const { partyId } = partyDetails

        setMessengerContext(contextType, partyId, true, displayName)
    }
    
    

    const {showHeader} = event

    let partyColSize

    if (showHeader) {
        partyColSize = {
            xs: 6,
            md: 3
        }
    } else {
        const availPeers = partyDetails.peers.length

        if (availPeers >= 5) {
            partyColSize = {
                xs: 6,
                md: 4
            }
        } else if (availPeers >= 3 ) {
            partyColSize = {
                xs: 6,
                md: 6
            }
        } else  {
            partyColSize = {
                xs: 12,
                md: 6
            }
        } 

    }

    return (
        <>

            { showHeader &&  (current.page !== PAGES.PRE_EVENT_FORM) && 
                <EventHeroComponent event={event} isActive={true} />
            }

            
            { isShowingMaster && (

                <Row style={{ maxHeight: '30vh' }}>
                    <Col xs={12} md={11} >

                        <Row>
                            { (current.page === PAGES.INTERMISSION) && 
                                <IntermissionView goToParty={goToParty} partyColSize={partyColSize} />
                            }

                            { (current.page === PAGES.IN_PARTY) && 
                                <PartyComponent 
                                    hasHeader={event.showHeader}
                                    profile={profile}
                                    partyId={currentPartyId}
                                    partyDetails={partyDetails} 
                                    event={event} 
                                    partyColSize={partyColSize}
                                /> 
                            }

                            <Col key={profile.profileId} xs={partyColSize.xs} md={partyColSize.md} >
                                <MasterStreamComponent getPartyDetails={goToIntermission} channelArn={hostDetails.channelArn} />  
                            </Col>


                        </Row>

                    </Col>


                    <Col xs={12} md={1} >
                        <Row>




                            <Col xs={3} md={12} className="text-center mt-3">
                                <Tooltip placement="top" isOpen={messengerTooltipOpen} target="partyChat" toggle={toggleMessengerTt}>
                                    Party Chat
                                </Tooltip>
                                <Button onClick={openMessengerContext} className={fabStyle} color="primary" id="partyChat" >
                                    <FontAwesomeIcon icon={faComment} />
                                </Button>
                            </Col>

                            <Col xs={3} md={12} className="text-center mt-3">
                                <Tooltip placement="top" isOpen={nextTooltipOpen} target="nextParty" toggle={toggleNextTt}>
                                    Next Party
                                </Tooltip>
                                <Button onClick={goToIntermission} className={fabStyle} color="primary" id="nextParty"  >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </Button>
                                </Col>



                            <Col xs={3} md={12} className="text-center mt-3">
                                <Tooltip placement="top" isOpen={settingsTooltipOpen} target="settings" toggle={toggleSettingsTt}>
                                    Settings
                                </Tooltip>
                                <Button onClick={() => alert('settings')} className={fabStyle} color="primary" id="settings"  >
                                    <FontAwesomeIcon icon={faCog}  />
                                </Button>
                            </Col>






                        </Row>

                    </Col>

                </Row>

                
            )}
        
            

           

            {resolveView()}
        </>
    )
}


export default EventDirector