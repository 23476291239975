import React, { useState } from 'react'
import { Row, Col, Button, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube, faCompass, faUser, faBullhorn, faGlassCheers, faGlassMartiniAlt } from '@fortawesome/free-solid-svg-icons'




const Tesseract = () => {

    const isMobile = window.innerWidth < 600
  
    const svgViewBox =  "0 0 100 5"
    const polygon =  "0 0,0 5,50 0,100 5, 100 0"
  
  
    return (

        <div className="App-logo block">
          <div className="shape">
            <div className="cube outer">
              <div className="side left"></div>
              <div className="side right"></div>
              <div className="side top"></div>
              <div className="side bottom"></div>
              <div className="side front"></div>
              <div className="side back"></div>
              
              
              <div className="cube">
                <div className="side left"></div>
                <div className="side right"></div>
                <div className="side top"></div>
                <div className="side bottom"></div>
                <div className="side front"></div>
                <div className="side back"></div>
              </div>
              
            </div>
          </div>
        </div>
    )
  }



const determineHeroItem = (appId) => {

  const isLarge = window.innerWidth > 991
  const isXLarge = window.innerWidth > 1200

  let mockupStyle = {}

  if (isXLarge) {
    mockupStyle = {width: '71%', position: 'absolute', left: '80px', zIndex: '-1', top: '33px' }
  } else if (isLarge) {
    mockupStyle = {width: '70%', position: 'absolute', left: '69px', zIndex: '-1', top: '33px' }
  } else {
    mockupStyle = {width: '68%', position: 'absolute', left: '55px', zIndex: '-1', top: '23px' }
  }


  switch (appId) {
    case 'HOTBOX':
      return <>
        <img width='100%' src='https://www.propertyshark.com/Real-Estate-Reports/wp-content/uploads/2016/02/laptop-mockup.png' />
        <img width='100%' style={mockupStyle} src={'https://s3.amazonaws.com/dbrown-video-stream.com/gif-hotbox-french.gif'} />
      </>
    case 'APELLA':
      return <FontAwesomeIcon className="my-3 hero-item" size="10x" icon={faBullhorn} />
    case 'SPEAKEASY':
      return <FontAwesomeIcon className="my-3 hero-item" size="10x" icon={faGlassCheers} />
    case 'TESSERACT':
      return <Tesseract />
    default:
      return <FontAwesomeIcon className="my-3 hero-item" size="10x" icon={faCube} />
  
  }

}


const LandingPage = ({ appConfig, goToExplore }) => {


    // const svgViewBox =  "0 0 100 5"
    // const polygon =  "0 0,0 5,50 0,100 5, 100 0"


    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
  

    return (
      <Container>
        <Row>
            <Col xs={12} className=" mt-5" >
                <Row style={{minHeight: '50vh' }} >
                    <Col xs={12} sm={6} className="text-center d-flex"  >

                      <Row className="m-auto" >
                        <Col>

                          { determineHeroItem(appConfig.appId) }

                        </Col>
                      </Row>
                        {/* <Tesseract /> */}


                    </Col>
                    <Col xs={12} sm={6} className="d-flex"  >
                      <Row className="my-auto font-secondary pt-4" >
                        <Col>
                        
                          <Row className="mb-3 text-center">
                              <Col xs={12}>
                                  <h3 style={{ fontSize: '50px' }}>{ appConfig.landing.header }</h3>
                              </Col>
                              <Col xs={12}>
                                  <span  style={{ fontSize: '22px' }}>{ appConfig.landing.subHeader }</span>
                              </Col>
                          </Row>
                          <Row className="mt-5" >
                              {/* <Col xs={6}>
                                  <Button block outline color="primary" >{ appConfig.landing.cta.secondary.text }</Button>
                              </Col> */}
                              <Col xs={{ size: 6, offset: 3 }}>
                                  <Button block color="primary" onClick={goToExplore} >{ appConfig.landing.cta.primary.text }</Button>
                              </Col>
                          </Row>

           
                        </Col>
                      </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
      </Container>
    )
}

export default LandingPage