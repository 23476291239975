


export const ApellaAppConfig = {
    appId: 'APELLA',
    name: 'Apella',
    logo: 'Apella',
    pages: [
      // 'LANDING',
      'PROFILE',
      'EXPLORE',
      'MESSENGER'

    ],
    type: 'PROFILE_APPS',
    forceSso: true,
    landing: {
      page: 'LANDING',
      header: 'Podium for the world',
      subHeader: 'A tool to broadcast your message and talent',
      cta: {
        primary: {
          text: 'Explore'
        },
        secondary: {
          text: 'Learn More'
        }
      }
    },
    pageConfigs: [
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [
          'EVENTS',
          'FRIENDS',
          'GROUPS'
        ]
      },
      {
        key: 'EXPLORE',
        isAuthorized: false,
        headerText: 'Current and Upcoming Digital Events',
        tabs: [ ],
      },
      {
        key: 'EVENT',
        isAuthorized: true,
        tabs: [ ],
      },
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [ ]
      }
    ],  
    events: [
      {
        key: 1,
        eventId: '0001',
        name: 'Fortnite Streamer',
        description: 'A livestream of somebody playing fortnite',
        type: 'PROFILE_EVENT',
        showHeader: true,
        maxPeers: 1000000,
        maxPartySize: 5,
        hosts: [
          {
            isPrimary: true,
            broadcastType: 'YOUTUBE',
            entityId: 'M2U4Y931WtA',
            name: 'Youtube Stream'
          }
        ]
      },
      {
        key: 2,
        eventId: '0002',
        name: '🔴M4 VS GRAU?!',
        description: 'FIGURING OUT WHAT IS THE BEST WARZONE WEAPON! (DUMB CHALLENGES TOO!)',
        type: 'PROFILE_EVENT',
        showHeader: true,
        maxPeers: 1000000,
        maxPartySize: 5,
        hosts: [
          {
            isPrimary: true,
            broadcastType: 'YOUTUBE',
            entityId: 'VLHr5ogmkNo',
            name: 'Youtube Stream'
          }
        ]
      },
      {
        key: 3,
        eventId: '0003',
        name: 'Toke Up Tuesday',
        description: 'Join the crowd at Hotbox for a Tuesday afternoon smoke sesh',
        status: 'BETA',
        type: 'REMOTE_URL',
        remoteUrl: 'https://app.tesseract.media/hotbox',
        path: 'hotbox'
      },
    ],
    theme: {
      color: {
        // primary: '#95389e',   // purple
        // secondary: '#f7f7f7',  // teal
        // tertiary: '#43d8c9', // blue
        // quaternary: '#100303', // off-white

        primary: '#5f81fc',   // primary blue
        secondary: '#f7f7f7',  // off-white
        tertiary: '#5cd6ff', // secondary blue
        quaternary: '#100303', // black


      },
      type: {
        primary: 'Grand Hotel',
        secondary: 'Oswald'
      }
    }
  }
  