import React, { useState } from 'react'
import { Row, Col, Spinner, Jumbotron, Container, Card, CardTitle, CardText, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { createAppEvent } from '../../services/bff-service'
import { mapAppEvents } from '../../utils/mapper'
import LikeComponent from '../../components/like-component'



const CreateEventModal = ({  toggle, isOpen }) => {

    const initialValues = {title: '', description: '', type: 'YOUTUBE', entityId: '' }
    const [form, setEventForm] = useState(initialValues)

    const handleChange = ({ target }) => {
        const { name, value } = target
        setEventForm({
            ...form,
            [name]: value
        })
    }

    const createNewEvent = () => {
        createAppEvent(form.title, form.description, form.type, form.entityId).then((createAppResult) => {
            setEventForm(initialValues)
            toggle()
        })
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} >
            <ModalHeader toggle={toggle}>Create Event</ModalHeader>
            <ModalBody>
            
                <FormGroup>
                    <Label for="title">Title</Label>
                    <Input type="title" name="title" id="title" onChange={handleChange} value={form.title} placeholder="Event title" />
                </FormGroup>
                <FormGroup>
                    <Label for="description">Description</Label>
                    <Input type="description" name="description" id="description" onChange={handleChange} value={form.description} placeholder="Event description" />
                </FormGroup>
            
                <FormGroup>
                    <Label for="type"  >Stream Type</Label>
                    <Input type="type" name="type" id="type" disabled onChange={handleChange} value={form.type} />
                </FormGroup>

                <FormGroup>
                    <Label for="entityId" disabled value="entityId" >Youtube Video Id</Label>
                    <Input type="entityId" name="entityId" id="entityId" onChange={handleChange} value={form.entityId} placeholder="Video ID" />
                </FormGroup>

            
            </ModalBody>
            <ModalFooter>
                <Button color="primary" block onClick={createNewEvent}>Create</Button>
            </ModalFooter>
        </Modal>
    )
}








const ExplorePage = ({ appConfig, authUser,  goToEventDetails, appEvents }) => {

    const [isModalOpen, setModalOpen] = useState(false)
    const toggle = () => setModalOpen(!isModalOpen)

    const pageConfig = appConfig.pageConfigs.find( c => c.key === 'EXPLORE' )

    const userSession = authUser ? authUser.signInUserSession : null
    const groups = userSession ? authUser.signInUserSession.accessToken.payload["cognito:groups"] : []
    const userIsAdmin = groups && groups.length ? groups.includes('admin') : false

    const isProfileApp = appConfig.type === 'PROFILE_APPS'



    return (
        <>
            <CreateEventModal toggle={ toggle } isOpen={isModalOpen} />

            <Container>

                <Jumbotron className="text-center" >
                    <h2>{ pageConfig.headerText }</h2>

                    { userIsAdmin && isProfileApp && 
                        <Row className="mt-5 mr-0" >
                            <Col xs={12} md={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }} className="mr-0 pr-0" >
                                <Button className="w-100" color="secondary" onClick={toggle} >Create Event</Button>
                            </Col>
                        </Row>
                    }
                
                </Jumbotron>

                { appEvents.length > 0 
                    ?   <Row>
                            { appEvents.map(event => 
                                <Col xs={12} sm={6} md={4} lg={3} >

                                    <Card body style={{ minHeight: '200px' }} className="mb-3" >
                                        <CardTitle>
                                            <strong>{ event.title }</strong>                             
                                        </CardTitle>
                                        <CardText>{ event.description } </CardText>
                                        <Button className="mt-auto" color="primary" onClick={goToEventDetails(event)} >View Details</Button>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    :   <Row>
                            <Col xs={12} className="text-center">
                                <Spinner color="primary" />
                            </Col>
                        </Row>
                }

            </Container>


        </>
    )
}

export default ExplorePage