

export const SpeakeasyAppConfig = {
    appId: 'SPEAKEASY',
    name: 'Speakeasy',
    logo: 'Speakeasy',
    pages: [
      'LANDING',
      // 'EXPLORE',
      'PROFILE',
      'MESSENGER'
    ],
    type: 'SINGLE',
    forceSso: true,
    landing: {
      page: 'LANDING',
      header: `A SOCIAL PLACE`,
      subHeader: 'BRINGING THE WORLD TOGETHER ONE SESH AT A TIME',
      cta: {
        primary: {
          text: 'JOIN THE PARTY',
          target: 'EVENT'
  
        },
        secondary: {
          text: 'LEARN MORE',
          target: 'EVENT'
        }
      }
    },
    pageConfigs: [
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [
          // 'EVENTS'
          'FRIENDS',
          // 'GROUPS'
        ]
      },
      {
        key: 'EXPLORE',
        isAuthorized: false,
        tabs: [ ]
      },
      {
        key: 'EVENT',
        isAuthorized: false,
        tabs: [ ]
      }
    ],
    event: {
      eventId: '2020',
      name: 'Speakeasy Social',
      description: `The last bar open in quarantine`,
      showHeader: false,
      maxPeers: 1000000,
      maxPartySize: 9
    },
    theme: {
      color: {
        primary: '#eb4559',   // green
        secondary: '#f7f7f7',  // off-white
        tertiary: '#5cd6ff', // secondary blue
        quaternary: '#100303', // black
      },
      type: {
        primary: 'Limelight',
        secondary: 'Oswald'
      }
    }
  }
  
  