import React, { useState } from 'react'
import { Button, ButtonGroup, Container, Row, Col, ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faVideo, faUser, faPlus  } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import ProfileHeroComponent from '../../../hero/profile-hero-component'
import { mapAppEvents } from '../../../../utils/mapper'


const PAGES = {
    FRIENDS: 'FRIENDS',
    EVENTS: 'EVENTS',
    GROUPS: 'GROUPS'
}



const GroupsList = ({ setMessengerContext }) => {

    const member = {
        displayName: 'Ralph',
    }

    const group = {
        id: 'asdfasdf',
        name: '/barkbox/',
        members: [ member, member ]
    }

    const groups = [ group, group ]


    const openMessageContext = (groupId) => () => {
        const contextType = 'GROUP'
        setMessengerContext(contextType, groupId)
    }

    const joinPrivateParty = (groupId) => () => {
        alert(`Joining private group party: ${groupId}`)

        const userAction = {

        }
    }

    return (
        <>
            <Row className="mt-3" >
                <Col className="text-right" >
                    <Button>
                        <FontAwesomeIcon className="mr-2" icon={faPlus} color="primary"  />
                        Create Group
                    </Button>
                </Col>
            </Row>

            <ListGroup className="mt-3">
                { groups.length && groups.map(group => {
                    
                    return (
                        <ListGroupItem>
                            <Row>
                                <Col>
                                    <h4>{ group.name } </h4>
                                </Col>
                                <Col className="text-center">
                                    { group.members.length && group.members.map((member, i) => {
                                        let m =   i === 0 ? '' : ', '
                                        m += member.displayName
                                        return m
                                    })}
                                </Col>
                                <Col xs={12} md={6} className="text-right color-primary" >
                                    <Button onClick={openMessageContext(group.id)} className="ml-3" >
                                        <FontAwesomeIcon icon={faEnvelope} color="primary"  />
                                    </Button>
                                    <Button onClick={joinPrivateParty(group.id)} className="ml-3" >
                                        <FontAwesomeIcon icon={faVideo}  color="primary"   />
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    )
                
                })}
            </ListGroup>
        </>
    )
}






const EventsList = ({ events, joinEvent }) => {


    const isBetweenDates = (startTime, endTime) => {
        return moment().isBetween(startTime, endTime)
    }

    if (events.length === 0) {
        return <i className="mt-3" >No liked events yet</i>
    }



    return (
        <ListGroup className="mt-3">

            { events.length && mapAppEvents(events).map(event => {
                
                
                const isLive = isBetweenDates(event.startTime, event.endTime) || event.eventId === "1463c691-001d-4e1f-ae47-5f558b817a84"


                return (
                    <ListGroupItem >
                        <Row>
                            <Col>
                                <h4>{ event.title } </h4>
                            </Col>
                            <Col className="text-center">
                                { isLive 
                                    ? <Button color="primary" onClick={joinEvent(event)}>Join Now</Button>
                                    : moment(event.startTime).fromNow() }
                            </Col>
                            <Col className="text-right" >
                                <FontAwesomeIcon className="mr-2" icon={faUser} color="primary"  />{ event.numUsers }
                            </Col>
                        </Row>
                    </ListGroupItem>
                )
            
            })}
        </ListGroup>
    )
}



const FriendsList = ({ friends = [], messenger, setMessengerContext }) => {


    const openMessageContext = ({ profileId, displayName }) => () => {
        const contextType = 'PEER'
        setMessengerContext(contextType, profileId, true, displayName)
    }



    if (friends.length === 0) {
        return <i className="mt-3" >No friends yet</i>
    }

    return (
        <ListGroup className="mt-3">

            { friends.length && friends.map(friend => 
                <ListGroupItem>
                    <Row>
                        <Col xs={12} md={6} >
                            { friend.displayName } 

                        </Col>
                        <Col xs={12} md={6} className="text-right color-primary" >
                            <FontAwesomeIcon className="" icon={faEnvelope} color="primary" onClick={openMessageContext(friend)}  />
                            {/* <FontAwesomeIcon className="ml-3" icon={faVideo}  color="primary" onClick={inviteToParty}  /> */}

                        </Col>
                    </Row>
                </ListGroupItem>
            )}
        </ListGroup>

    )
}



const MenuBar = ({ current, setCurrent, show }) => {

    const setCurrentView = (page) => () => {
        setCurrent({ page })
    }

    return (
        <Row className="mt-3">
            <Col xs={12}>
                <ButtonGroup className="w-100" >
                    { show.events && <Button color="primary" className="w-100" active={current.page === PAGES.EVENTS} onClick={setCurrentView(PAGES.EVENTS)} >Events</Button> }                    
                    { show.friends && <Button color="primary" className="w-100"  active={current.page === PAGES.FRIENDS} onClick={setCurrentView(PAGES.FRIENDS)} >Friends</Button> }
                    { show.groups && <Button color="primary" className="w-100"  active={current.page === PAGES.GROUPS} onClick={setCurrentView(PAGES.GROUPS)} >Groups</Button> }
                </ButtonGroup>
            </Col>
        </Row>
  
    )
}


const ProfileDirector = ({ appConfig, profile, messenger, authUser, setMessengerContext, goToEvent }) => {

    const pageConfig = appConfig.pageConfigs.find(c => c.key === 'PROFILE')

    const showEvents = pageConfig.tabs.includes(PAGES.EVENTS)
    const showFriends = pageConfig.tabs.includes(PAGES.FRIENDS)
    const showGroups = pageConfig.tabs.includes(PAGES.GROUPS)

    const show = {
        events: showEvents,
        friends: showFriends,
        groups: showGroups
    }

    const initialPage = 
        showEvents ? PAGES.EVENTS : 
        showFriends ? PAGES.FRIENDS : 
        showGroups ? PAGES.GROUPS : PAGES.FRIENDS
    
    const initial = {
        page: initialPage
    }

    const [ current, setCurrent ] = useState(initial)


    const resolveView = () => {
        switch(current.page) {
            case PAGES.EVENTS:
                return <EventsList events={profile.events} joinEvent={goToEvent} />
            case PAGES.FRIENDS:
                return <FriendsList friends={profile.friends} messenger={messenger} setMessengerContext={setMessengerContext} />
            case PAGES.GROUPS:
                return <GroupsList groups={profile.groups} setMessengerContext={setMessengerContext} />
            default:
                return <> Hmm, not sure how you got here </>
        }
    }

    if (!profile) {
        return (
            <Container>
                <Spinner />
            </Container>
        )
    }

    return (
        <Container>
            <ProfileHeroComponent profile={profile} authUser={authUser} />
            <MenuBar current={current} setCurrent={setCurrent} show={show} />
            { resolveView() }

        </Container>
    )
}

export default ProfileDirector
