import React, { useEffect, useState } from 'react'
import LandingPage from '../../../pages/landing-page'
import ExploreDirector from './explore-director'
import EventDirector from './event-director'
import ProfileDirector from './profile-director'
import NavComponent from '../../nav-component'
import FooterComponent from '../../footer'
import MessengerListModal from '../../messenger-list-modal'
import NotificationComponent from '../../notification-component'

import AuthorizeComponent from '../../authorize'
import SsoComponent from '../../sso-component'
import { mapAppEvents } from '../../../utils/mapper'



const AppDirector = ({ 
    appData, 
    authUser, 
    partyDetails, 
    addPeers, 
    profile, 
    setMessengerContext, 
    messenger, 
    setProfile, 
    linkProfileRef, 
    notification,
    showSystemUserModal, 
    toggleSystemUserModal,
    getAuthenticatedSession,
    appEvents,
    isSessionLoaded
 }) => {


    const DIRECTORS = {
        LANDING: 'LANDING',
        EXPLORE: 'EXPLORE',
        EVENT_DETAILS: 'EVENT_DETAILS',
        EVENT: 'EVENT',
        PROFILE: 'PROFILE',
        AUTH: 'AUTH'
    }

    const initial = {
        path: 'LANDING'
    }

    const [ current, setCurrent ] = useState(initial)


    const [ showMessenggerList, setShowMessengerList ] = useState(false)


    const toggleMessenger = () => setShowMessengerList(!showMessenggerList)



    const goTo = (path, params) => setCurrent({ ...current, ...params, path })

    const goToAdapter = (path) => () => goTo(path)

    const goToCta = () => {
        const landing = appData.landing.cta.primary.target
        if (landing === DIRECTORS.EVENT) {
            goTo(DIRECTORS.EVENT, { event: appData.event })
        } else if (landing === DIRECTORS.EXPLORE) {
            goTo(DIRECTORS.EXPLORE)
        } else {
            goTo(DIRECTORS.EXPLORE)
        }
    }

    const goToEvent = (event) => () => {

        const isLiked = isEventLiked(event.eventId)

        console.log({ events: profile.events, event })

        const currentPayload = {
            event: {
                ...event,
                isLiked
            }
        }

        goTo(DIRECTORS.EVENT, currentPayload)
    }

    const isEventLiked = (eventId) => {
        console.log('isEventLiked')
        if (profile && profile.events) {
            const { events } = profile
            
            
            const isLiked = !!events.find( e => e.eventId == eventId )

            console.log({ isLiked, eventId, events })

            return isLiked
        } else {
            return false
        }
    }

    const processAppEvents = (items = []) => {

        const isProfileApp = appData.type === 'PROFILE_APPS'

            
        const processed = []
        const mapped = isProfileApp ? mapAppEvents(items) : appData.events

        if (profile && profile.events) {
            mapped.map( (item) => {
                processed.push({
                    ...item,
                    isLiked: isEventLiked(item.eventId)
                })
            })
            console.log({ processed})

            return processed
        } else {
            return mapped
        }
    }


    const resolveView = () => {

        switch (current.path)  {
            case DIRECTORS.LANDING:
                return <LandingPage appConfig={appData} goToExplore={goToCta} />
            case DIRECTORS.EXPLORE:
                return <ExploreDirector appConfig={appData} authUser={authUser} goToEvent={goToEvent} appEvents={processAppEvents(appEvents)} />
            case DIRECTORS.EVENT:
                return (
                    <AuthorizeComponent cognitoUser={authUser} page={DIRECTORS.EVENT} appData={appData} render={() => (
                        <EventDirector 
                            appConfig={appData} 
                            authUser={authUser}
                            profile={profile || current.profile} 
                            event={current.event} 
                            partyDetails={partyDetails} 
                            addPeers={addPeers} 
                            setMessengerContext={setMessengerContext} 
                            setProfile={setProfile}
                            linkProfileRef={linkProfileRef}
                            isSessionLoaded={isSessionLoaded}
                        />
                    )} />
                )
            case DIRECTORS.PROFILE:
                return (
                    <AuthorizeComponent cognitoUser={authUser} page={DIRECTORS.PROFILE} appData={appData} render={() => (
                        <ProfileDirector 
                            authUser={authUser} 
                            appConfig={appData} 
                            profile={profile} 
                            messenger={messenger} 
                            setMessengerContext={setMessengerContext}
                            goToEvent={goToEvent} 
                            />
                    )} />
                )
            default:
                return <>Something went wrong</>
        }
    }


    const notificationContexts = {
        PEER: 'PEER',
        GROUP: 'GROUP',
        PARTY: 'PARTY'
    }

    const notificationTypes = {
        NEW_MESSAGE: 'NEW_MESSAGE',
        
    }

    // const notification = 
    //     {
    //         context: notificationContexts.PEER,
    //         type: notificationTypes.NEW_MESSAGE,
    //         contextName: 'Party Chat',
    //         text: 'sup retard',
    //         senderName: 'Apu',
    //         action: () => alert('hello')
    //     }
    
    // console.log({ profile, current })

    return (
        <>
    
            <SsoComponent appConfig={appData} isOpen={showSystemUserModal} toggle={toggleSystemUserModal} getAuthenticatedSession={getAuthenticatedSession} authUser={authUser} />

            <NotificationComponent notification={notification} />

            <NavComponent 
                cognitoUser={authUser}
                appConfig={appData} 
                currentView={current} 
                goTo={goToAdapter} 
                profile={profile || current.profile} 
                toggleMessenger={toggleMessenger} 
                showMessenger={showMessenggerList}  
                />

            <MessengerListModal 
                isOpen={showMessenggerList} 
                toggle={toggleMessenger}
                profile={profile} 
                setMessengerContext={setMessengerContext}
                />

            { resolveView() }
            
            <FooterComponent appConfig={appData} page={current.path} />
        </>
    )
}

export default AppDirector
