import { HotboxAppConfig } from '../config/hotbox'
import { TesseractAppConfig } from '../config/tesseract'
import { ApellaAppConfig } from '../config/apella'
import { SpeakeasyAppConfig } from '../config/speakeasy'


export const getAppId = () => {
    const { href, pathname } = window.location

    let currentApp = ''

    if (href.includes('localhost')) {
        if (pathname.includes('hotbox')) {
            currentApp = 'HOTBOX'
        } else if (pathname.includes('apella')) {
            currentApp = 'APELLA'
        } else if (pathname.includes('speakeasy')) {
            currentApp = 'SPEAKEASY'
        } else {
            currentApp = 'TESSERACT'
        }
    } else if (href.includes('tesseract')) {
        if (pathname.includes('hotbox')) {
            currentApp = 'HOTBOX'
        } else if (pathname.includes('apella')) {
            currentApp = 'APELLA'
        } else if (pathname.includes('speakeasy')) {
            currentApp = 'SPEAKEASY'
        } else {
            currentApp = 'TESSERACT'
        }
    } else if (href.includes('hotboxsocial')) {
        currentApp = 'HOTBOX'
    } else if (href.includes('ape')) {
        currentApp = 'APELLA'
    } else if (href.includes('speakeasy')) {
        currentApp = 'SPEAKEASY'
    } 


    return currentApp

}

export const getCurrentBrandConfig = () => {

    const currentApp = getAppId()

    // console.log({ currentApp })

    let appConfig = {}

    switch (currentApp) {

        case 'TESSERACT':
            appConfig = TesseractAppConfig
            break
        case 'HOTBOX':
            appConfig = HotboxAppConfig
            break
        case 'APELLA':
            appConfig = ApellaAppConfig
            break
        case 'SPEAKEASY':
            appConfig = SpeakeasyAppConfig
            break
        default:
            appConfig = TesseractAppConfig
    }

    return appConfig
}

