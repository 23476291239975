
import React from 'react'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube } from '@fortawesome/free-solid-svg-icons'
import { SocialIcon } from 'react-social-icons';


const SocialCardComponent = ({ socials }) => {


    return (
        <Row>
            <Col>
                <h4>Socials</h4>
                {/* <SocialIcon className="mr-2 mb-2" network="twitter" url="http://twitter.com" target="_blank" rel="noopener noreferrer" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="dribbble" url="http://twitter.com"target="_blank" rel="noopener noreferrer"  /> */}
                <SocialIcon className="mr-2 mb-2" network="linkedin" url="http://twitter.com" target="_blank" rel="noopener noreferrer" />
                <SocialIcon className="mr-2 mb-2" network="instagram" url="http://twitter.com" target="_blank" rel="noopener noreferrer" />
                <SocialIcon className="mr-2 mb-2" network="github" url="http://twitter.com" target="_blank" rel="noopener noreferrer"  />
                {/* <SocialIcon className="mr-2 mb-2" network="facebook" url="http://twitter.com" target="_blank" rel="noopener noreferrer" /> */}
                <SocialIcon className="mr-2 mb-2" network="reddit" url="http://twitter.com" target="_blank" rel="noopener noreferrer" />
                <SocialIcon className="mr-2 mb-2" network="soundcloud" url="http://twitter.com" target="_blank" rel="noopener noreferrer" />
                {/* <SocialIcon className="mr-2 mb-2" network="snapchat" url="http://twitter.com" target="_blank" rel="noopener noreferrer" /> */}
                <SocialIcon className="mr-2 mb-2" network="itunes" url="http://twitter.com" target="_blank" rel="noopener noreferrer" />
                {/* <SocialIcon className="mr-2 mb-2" network="spotify" url="http://twitter.com" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="vsco" url="http://twitter.com" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="tumblr" url="http://twitter.com/" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="twitch" url="http://twitter.com" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="youtube" url="http://twitter.com" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="pinterest" url="http://twitter.com" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="medium" url="http://twitter.com" /> */}
                {/* <SocialIcon className="mr-2 mb-2" network="squarespace" url="http://twitter.com" /> */}



            </Col>
        </Row>

    )
}

export default SocialCardComponent