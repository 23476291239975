


export const TesseractAppConfig = {
  appId: 'TESSERACT',
  name: 'Tesseract Media',
  logo: 'TESSERACT MEDIA',
  pages: [
    'LANDING',
    'EXPLORE',
  ],
  type: 'MULTI',
  forceSso: false,
  landing: {
    page: 'LANDING',
    header: 'DYNAMIC EXPERIENCES',
    subHeader: 'Orchestrating dynamic digital experiences for your brand',
    cta: {
      primary: {
        text: 'Search Apps',
        target: 'EXPLORE'
      },
      secondary: {
        text: 'Learn More',
        target: 'EXPLORE'

      }
    }
  },
  pageConfigs: [
    {
      key: 'EXPLORE',
      headerText: 'Current Platform Brands',
      isAuthorized: false,
      tabs: [ ]
    },
    {
      key: 'EVENT',
      headerText: 'Current Platform Brands',
      isAuthorized: false,
      tabs: [ ]
    }
  ],  
  events: [
    {
      key: 3,
      eventId: '',
      title: 'Apella',
      status: 'BETA',
      description: 'The community podium for the world',
      type: 'REMOTE_URL',
      remoteUrl: 'https://app.tesseract.media/apella',
      path: 'apella'
    },
    {
      key: 2,
      eventId: '2020',
      title: 'Speakeasy',
      status: 'BETA',
      description: 'The last club open in the quarantine',
      type: 'REMOTE_URL',
      remoteUrl: 'https://speakeasysocial.app',
      path: 'speakeasy'
    },
  ],
  theme: {
    color: {
      primary: '#2a7886',   
      secondary: '#f1f3f4',  
      tertiary: '#79bac1', 
      quaternary: '#512b58', 
    },
    // color: {
    //   primary: '#c2b0c9',   
    //   secondary: '#f4efd3',  
    //   tertiary: '#cccccc', 
    //   quaternary: '#9656a1', 
    // },
    type: {
      primary: 'Orbitron',
      secondary: 'Oswald'
    }
  }
}
