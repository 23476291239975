import React from 'react'
import AppOrchestrator from './components/orchestrator'

import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';


Amplify.configure(aws_exports);

const App = () => {

  return (
    <AppOrchestrator />
  )
}


export default App