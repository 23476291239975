import React, { useState } from 'react'
import ExplorePage from '../../../../pages/explore-page'
import EventDetailsPage from '../../../../pages/event-details-page'
import { Col, Row, Button, Container } from 'reactstrap'

const ExploreDirector = ({ appConfig, authUser, goToEvent, appEvents }) => {


    const PAGES = {
        EXPLORE: 'EXPLORE',
        EVENT_DETAILS: 'EVENT_DETAILS'
    }

    const initial = {
        page: 'EXPLORE'
    }


    

    const [ current, setCurrent ] = useState(initial)

    const goToListView = () => {
        setCurrent({ page: PAGES.EXPLORE })
    }

    const goToEventDetails = (event) => () => setCurrent({ page: PAGES.EVENT_DETAILS, event })

    const resolveView = () => {
        switch(current.page) {
            case PAGES.EXPLORE:
                return <ExplorePage appConfig={appConfig} authUser={authUser} goToEventDetails={goToEventDetails} appEvents={appEvents} />
            case PAGES.EVENT_DETAILS:
                return <EventDetailsPage event={current.event} goToEvent={goToEvent(current.event)} />
            default:
                return <> Hmm, not sure how you got here </>
        }
    }

    return (
        <>

            { current.page === PAGES.EVENT_DETAILS && 
                <Container>
                    <Row className="mb-3" >
                        <Col>
                            <Button onClick={goToListView} >
                                Back to list
                            </Button>
                        </Col>
                    </Row>            
                </Container>
            }

            { resolveView() }

        </>
    )
}


export default ExploreDirector